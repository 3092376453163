import React, { useState, useEffect, } from 'react'
import { PowerBIEmbed } from 'powerbi-client-react'
import { models } from 'powerbi-client'
import './Analytics.css'
import axiosInstance from '../../../Services/api.js'
const MahindraAnalytics = () => {

    const [powerBiDetails, setPowerBiDetails] = useState(null)
    const powerBiReportId = process.env.REACT_APP_MANAGER_POWERBI_REPORT_ID  


    const fetchPowerbiDetails = async () => {

        const details = await axiosInstance.post('analytics/get_embed_token', {
            'reportId': "88a2da84-a936-43de-8356-520a772936e1"
            // process.env.REACT_APP_BI_ACCENTURE_REPORT
        })
        console.log(details);
        setPowerBiDetails(details)
    }


    useEffect(() => {

        fetchPowerbiDetails();
    }, [])


    return (
        <div style={{ height: '100%', width: '100%' }} className="bodyContainer">
            <div style={{ height: '100%', width: '100%' }} className="">
                {console.log("Test commit")}
                <PowerBIEmbed
                    embedConfig={{
                        type: 'report',
                        // id: powerBiDetails?.embedUrl[0].reportId,
                        id: powerBiReportId,
                        // embedUrl: powerBiDetails?.embedUrl[0].embedUrl,
                        embedUrl: powerBiDetails?.embedUrl[0].embedUrl,

                      
                        accessToken: powerBiDetails?.accessToken,
                        tokenType: models.TokenType.Embed, 

                        settings: {
                            panes: {
                                filters: {
                                    expanded: false,
                                    visible: false
                                }
                            },
                            // background: models.BackgroundType.Transparent,
                        },

                    }}


                    eventHandlers={
                        new Map([
                            ['loaded', function () { 
                                console.log('Loaded report powerBi')
                            }],
                            ['rendered', function () { }],
                            ['error', function (event) { console.log(event.detail); }],
                            ['visualClicked', () => console.log('visual clicked')],
                            ['pageChanged', (event) => console.log(event)],
                        ])
                    }

                    cssClassName={"embed-container"}

                    getEmbeddedComponent={(embeddedReport) => {
                        window.report = embeddedReport;

                    }}
                />
            </div>
        </div>
    )
}

export default MahindraAnalytics;




