export const ROUTES = {
    login: '/',
    vedhik:'/vedhik',
    mednet: '/mednet',
    accenture: '/accenture',
    mahindra: '/mahindra',
    stanford: '/stanford',
    skyhigh: '/skyhigh',
    nissan: '/nissan',
    vineyard: '/vineyard',
}