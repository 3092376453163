import Login from "./Component/Login";
import Dashboard from "./Component/Dashboard";
import MonitorDashboard from "./Component/monitiorDasboard";
import Intermediate from "./Component/intermediate";
// import './App.css'
import { Route, Routes, BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SideMenu from "./Component/SideMenu";
import PrivateRoute from "./lib/privateRoute";
import Layout from "./Component/Layout";
import HeaderProvider from "./Component/Context/HeaderProvider";
import Analytics from "./Pages/Analytics/Accenture/Analytics";
import { ROUTES } from "./config";
import VedhikAnalytics from "./Pages/Analytics/Vedhik/Analytics";
import MednetAnalytics from "./Pages/Analytics/Mednet/Analytics";
import AccentureAnalytics from "./Pages/Analytics/Accenture/Analytics";
import MahindraAnalytics from "./Pages/Analytics/Mahindra/Analytics";
import StanfordAnalytics from "./Pages/Analytics/Stanford/Analytics";
import SkyhighAnalytics from "./Pages/Analytics/Skyhigh/Analytics";
import NissanAnalytics from "./Pages/Analytics/Nissan/Analytics";
import VineyardAnalytics from "./Pages/Analytics/Vineyard/Analytics";

function App() {
  return (
    <>
     <ToastContainer
        className="customToast"
        autoClose={1}
        hideProgressBar={false}
        theme="dark"
      />
    <BrowserRouter>
      <Routes>
        <Route path={ROUTES.login} exact element={<Login />} />
        {/* <HeaderProvider> */}
        <Route element={<PrivateRoute/>} >
        <Route element={<Layout />}>
          <Route path={ROUTES.vedhik} exact element={<VedhikAnalytics />} />
          <Route path={ROUTES.mednet} exact element={<MednetAnalytics />} />
          <Route path={ROUTES.accenture} exact element={<AccentureAnalytics />} />
          <Route path={ROUTES.mahindra} exact element={<MahindraAnalytics />} />
          <Route path={ROUTES.mahindra} exact element={<MahindraAnalytics />} />
          <Route path={ROUTES.stanford} exact element={<StanfordAnalytics />} />
          <Route path={ROUTES.skyhigh} exact element={<SkyhighAnalytics/>} />
          <Route path={ROUTES.nissan} exact element={<NissanAnalytics/>} />
          <Route path={ROUTES.vineyard} exact element={<VineyardAnalytics/>} />
        </Route>
        </Route>
        {/* </HeaderProvider> */}
      </Routes>
    </BrowserRouter>
    </>
  );
}

export default App;
