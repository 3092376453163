import axios from "axios";


const API_BASE = 'api/v1/';

console.log("API_BASE", API_BASE);

const axiosInstance = axios.create({
baseURL: API_BASE,
headers: {
  'Content-Type': 'application/json',
},
})

// Request interceptor
axiosInstance.interceptors.request.use(
  (config) => {
    // const UserInfo = localStorage.getItem("UserInfo");
    // localStorage.getItem("UserInfo")
    // config.baseURL = API_BASE;
    if (config.data instanceof FormData) {
      config.headers['Content-Type'] = 'multipart/form-data';
    } else {
      config.headers['Content-Type'] = 'application/json';
    }
    // if (UserInfo) {
    //   const _UserInfo = JSON.parse(UserInfo);
    //   config.headers.Authorization = `Bearer ${_UserInfo.token}`;
    // }
        // config.headers.Authorization = `Bearer accessToken`;
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

// Response interceptor
axiosInstance.interceptors.response.use(
  (res) => {
    const response = res.data;
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default axiosInstance